<template>
    <div class="wrapper">
        <!-- Alert Component -->
        <CAlert
            color="danger"
            :show.sync="alertDanger"
            closeButton
        >
          Acción No Realizada.
          <CProgress
            color="info"
            :max="dismissSecs"
            :value="alertDanger"
            height="4px"
          />
        </CAlert>

        <CAlert
            color="success"
            :show.sync="alertSuccess"
            closeButton
        >
          Acción Realizada con Éxito. 
          <CProgress
            color="info"
            :max="dismissSecs"
            :value="alertSuccess"
            height="4px"
          />
        </CAlert>

        <!-- Lista Component -->
        <div id="div_mercado">
          <CRow>
            <CCol col>
              <CCard>
                <CCardHeader>
                  <CIcon name="cil-justify-center"/>
                  <strong> Mercados</strong>
                  <div class="card-header-actions">
                      <CButton color="primary" @click="nuevoMercado()" class="mr-1">
                        Agregar Mercado
                      </CButton>
                  </div>
                </CCardHeader>
                <CCardBody>
                  <CDataTable
                    :items="arrayMercado"
                    :fields="fields"
                    column-filter
                    table-filter
                    items-per-page-select
                    :items-per-page="10"
                    hover
                    sorter
                    pagination
                  >
                    <template #estado="{item}">
                      <td>
                          <div v-if="item.status === 1">
                            Activo
                          </div>
                          <div v-else style="color:red;">
                            Inactivo
                          </div>
                      </td>
                    </template>
                    <template #acciones="{item}">
                      <td>
                        <CBadge>
                          <CButton color="primary" shape="pill" @click="ver_modal_selecciones(item.id, item.name)" v-bind:id="item.id">Seleccion</CButton>
                          <CButton @click="obtenerMercado(item.id, item.name, item.description, item.event_id)" color="success" shape="pill">Editar</CButton>
                          <CButton
                            color="danger"
                            variant="outline"
                            shape="pill"
                            @click="editarEstado(item.id, item.status)"
                          >
                            {{Boolean(item.status) ? 'Desactivar' : 'Activar'}}
                          </CButton>
                        </CBadge>
                      </td>
                    </template>
                  </CDataTable>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>

        <!-- Modal Component -->
        <!-- Modal MERCADO **************************************** -->
        <CModal
          :show.sync="modalMercado"
          :centered="true"
          size="lg"
          color="success"
        >
          <CRow>
              <CCol sm="12">
                  Seleccione un Evento:
                  <select id="modalMercado_id_evento" class="form-control" >
                      <option v-for="evento in arrayEventos" v-bind:id="evento.id">{{evento.name}}</option>
                  </select>
              </CCol>
            </CRow>
          <CRow>
            <CCol sm="12">
              <CInput
                label="Nombre"
                placeholder="Ingresar nombre del Mercado"
                id="modal_mercado"
                v-model="modal_mercado"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
                  <CTextarea
                    label="Descripción"
                    placeholder="..."
                    rows="5"
                    id="modal_description"
                    v-model="modal_description"
                  />
            </CCol>
          </CRow>
          <template #header>
              <h6 class="modal-title" id="modalMercado_h6_titulo"></h6>
              <CButtonClose @click="modalMercado = false" class="text-white"/>
          </template>
          <template #footer>
              <CButton @click="modalMercado = false" color="danger">Cancelar</CButton>
              <CButton @click="registrarMercado()" color="success" id="modalMercado_btn_guardar"></CButton>
          </template>
        </CModal>

        <!-- Modal SELECCION X MERCADO **************************************** -->

        <div id="div_seleccion_x_mercado" style="display:none;">
              <CCard>
                <CCardHeader>
                  <CIcon name="cil-justify-center"/>
                  <strong id="div_seleccion_x_mercado_titulo"></strong>
                </CCardHeader>
                <CCardBody>
            <CRow>
              <CCol sm="12">
                  Seleccione una sección:
                  <select id="modalSeleccion_id_seleccion" class="form-control" >
                      <option v-for="seleccion in arraySeleccionesNoUsadas" v-bind:id="seleccion.id">{{seleccion.name}}</option>
                  </select>
              </CCol>
            </CRow>
            <br>
            <CRow>
              <CCol sm="6">
                  <CInput
                    label="Cuota"
                    placeholder="Ingresar cuota"
                    id="modalSeleccion_cuota"
                    v-model="modalSeleccion_cuota"
                    type="number"
                  />
              </CCol>
              <CCol sm="6">
                <br>
                  <CButton @click="agregar_seleccion()" color="success">Agregar Seleccion</CButton>
              </CCol>
            </CRow>
            <hr>
            <br>
            <CRow>
              <CCol sm="12" id="modal_cuota_market">
                
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                  <CDataTable
                      :items="arraySeleccionesSiUsadas"
                      :fields="fields_seleccion"
                      table-filter
                      items-per-page-select
                      :items-per-page="5"
                      hover
                      sorter
                      pagination
                    >
                      <template #cuota="{item}">
                        <td>
                          <CInput
                            placeholder="Ingresar cuota"
                            type="number"
                            v-bind:value="item.pivot.price"
                            v-bind:id="'seleccionModal_i_cuota_'+item.pivot.id"
                          />
                        </td>
                      </template>
                      <template #acciones="{item}">
                        <td>
                          <CBadge>
                            <CButton color="success" shape="pill" @click="update_seleccion_x_market(item.pivot.id)">Editar</CButton>
                            <CButton color="danger" shape="pill" v-bind:id="item.id">Eliminar</CButton>
                          </CBadge>
                        </td>
                      </template>
                  </CDataTable>
              </CCol>
            </CRow>

            <hr>

                </CCardBody>
                <CCardFooter>
            <CButton @click="regresar_div_mercado()" color="dark">Regresar</CButton>
                </CCardFooter>
              </CCard>
        </div>

    </div>



</template>

<script>

import $ from 'jquery'
import axios from 'axios'

const fields = [
  { key: 'id', label: 'ID', _style:'width:1%' },
  { key: 'name', label: 'Mercado', _style:'min-width:200px' },
  { key: 'description', label: 'Descripción' },
  { key: 'estado', label: 'Estado', _style: 'width:1%' },
  { key: 'acciones', label: 'Acciones', _style: 'width:1%', sorter: false, filter: false }
]

const fields_seleccion = [
  { key: 'id', label: 'ID', _style:'width:1%' },
  { key: 'name', label: 'Seleccion', _style:'min-width:200px' },
  { key: 'cuota', label: 'Cuota', sorter: false, },
  { key: 'acciones', label: 'Eliminar', _style: 'width:1%', sorter: false, filter: false }
]

let cod_market=0;

export default{
    name: 'Inicio',
    data(){
        return{
            dismissSecs: 5,
            alertDanger: 0,
            alertSuccess: 0,
            arrayMercado:[],
            fields,
            arrayEventos:[],
            arraySeleccionesSiUsadas:[],
            arraySeleccionesNoUsadas:[],
            fields_seleccion,
            modalMercado: false,
            togglePress: true,
            modal_mercado: '',
            modal_description: '',
            modalSeleccion_cuota: ''
        }
    },
    methods:{
        countDownChanged (alertSuccess) {
            this.alertSuccess = alertSuccess
        },
        countDownChanged (alertDanger) {
            this.alertDanger = alertDanger
        },
        listarEventos() {
            let me = this;
            var url = 'v1/events/active';
            axios.get(url).then(function (response) {
                //console.log(response);
                me.arrayEventos = response.data['result'];
            })
                    .catch(function (error) {
                        console.log(error);
                    });
        },
        nuevoMercado(){
            $('#modalMercado_h6_titulo').html('Registrar Nuevo Mercado');
            $('#modalMercado_btn_guardar').html('Registrar');
            this.listarEventos();
            this.modal_mercado = '';
            this.modal_description = '';
            cod_market=0;
            this.modalMercado = true;
        },
        obtenerMercado(id, name, description, id_evento){
          console.log(id_evento);
            //this.listarEventos();
            $('#modalMercado_h6_titulo').html('Editar Seleccion');
            $('#modalMercado_btn_guardar').html('Editar');
            //$('#modalMercado_id_evento').val(id_evento);
            //this.modalMercado_id_evento=id_evento;
            this.modal_mercado = name;
            this.modal_description = description;
            cod_market=id;
            this.modalMercado = true;
            //$('#modalMercado_id_evento option[value="'+id_evento+'"]').attr("selected");
            //$('#modalMercado_id_evento').val(id_evento).trigger('change');
            //$('#modalMercado_id_evento option:selected').val(id_evento);
            //$('#modalMercado_id_evento').find('option:selected').val(id_evento).change();
            //$('#modalMercado_id_evento option[value="'+id_evento+'"]').attr("selected", true);
            $('#modalMercado_id_evento option[id="'+id_evento+'"]').attr("selected", "selected");
        },
        listarMercados(){
            let vue=this;
            axios.get('v1/markets')
                .then( function(response){
                   vue.arrayMercado=response.data['result'];
                  //console.log(vue.arraySelection);
                  })
        },
        editarEstado(id, status){
            let me = this;
            if(parseInt(status)===0){
                axios.put('v1/markets/activated', {
                    'id': id
                }).then(function (response) {
                    if (parseInt(response.data.http_code) === 200) {
                        me.listarMercados();
                        me.alertSuccess = 5;
                    } else {
                        me.alertDanger = 5;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
            if(parseInt(status)===1){
                axios.put('v1/markets/disabled', {
                    'id': id
                }).then(function (response) {
                    if (parseInt(response.data.http_code) === 200) {
                        me.listarMercados();
                        me.alertSuccess = 5;
                    } else {
                        me.alertDanger = 5;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        validarMercado() {
            let me = this;
            me.errorMercado = false;
            $("#modal_mercado").css("border", "");

            if (!(parseInt(me.modal_mercado.length) > 0)){
                $("#modal_mercado").css("border", "1px solid red");
                console.log("falta modal_mercado");
                me.errorMercado = true;
              }

            return me.errorMercado;
        },
        registrarMercado() {
            if (this.validarMercado()) {
                return;
            }

            let me = this;


            if(parseInt(cod_market)===0){
                axios.post('v1/markets', {
                    'name': this.modal_mercado,
                    'event_id': $("#modalMercado_id_evento").find('option:selected').attr('id'),
                    'description': this.modal_description
                }).then(function (response) {
                    if (parseInt(response.data.http_code) === 200) {
                        me.modal_mercado = '';
                        me.modal_description = '';
                        me.modalMercado = false;
                        me.alertSuccess = 5;
                        me.listarMercados();
                    } else {
                        me.alertDanger = 5;
                    }

                }).catch(function (error) {
                    console.log(error);
                });
            }
            if(parseInt(cod_market)>0){
                axios.put('v1/markets', {
                    'id': cod_market,
                    'name': this.modal_mercado,
                    'event_id': $("#modalMercado_id_evento").find('option:selected').attr('id'),
                    'description': this.modal_description
                }).then(function (response) {
                    if (parseInt(response.data.http_code) === 200) {
                        me.modal_mercado = '';
                        me.modal_description = '';
                        me.modalMercado = false;
                        me.alertSuccess = 5;
                        me.listarMercados();
                    } else {
                        me.alertDanger = 5;
                    }

                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        ver_modal_selecciones(id_market, name){
            $("#div_mercado").hide();
            $("#div_seleccion_x_mercado").show();
            //console.log(id_market);
            //console.log(name);
            cod_market=id_market;
            let me = this;
            $('#div_seleccion_x_mercado_titulo').html(' Selecciones para el Mercado: "'+name+'"');
            $("#modal_cuota_market").html('LISTA DE SELECCIONES DEL MERCADO: "'+name+'"');
            me.modalSeleccion_cuota = '';
            me.listarSeleccionesNoUsadas(id_market);
            me.listarSeleccionesSiUsadas(id_market);
        },
        listarSeleccionesNoUsadas(id_market) {
            let me = this;
            var url = 'v1/selections/active?market_id='+id_market;
            axios.get(url).then(function (response) {
                //console.log(response);
                me.arraySeleccionesNoUsadas = response.data['result'];
            })
                    .catch(function (error) {
                        console.log(error);
                    });
        },
        listarSeleccionesSiUsadas(id_market) {
            let me = this;
            var url = 'v1/markets/selections/filter?market_id='+id_market;
            axios.get(url).then(function (response) {
                //console.log(response);
                me.arraySeleccionesSiUsadas = response.data.result['selections'];
                //console.log(me.arraySeleccionesSiUsadas);
            })
                .catch(function (error) {
                    console.log(error);
                });
        },
        validarSeleccionxMarket() {
            let me = this;
            me.error = false;
            $("#modalSeleccion_cuota").css("border", "");

            if (!(parseFloat(me.modalSeleccion_cuota) > 0)){
                $("#modalSeleccion_cuota").css("border", "1px solid red");
                console.log("falta modalSeleccion_cuota");
                me.error = true;
              }

            return me.error;
        },
        agregar_seleccion(){
            //console.log(cod_market);
            if (this.validarSeleccionxMarket()) {
                return;
            }

            let me = this;
            var datos = [];
            datos.push({
                'market_id': cod_market,
                'selection_id': $("#modalSeleccion_id_seleccion").find('option:selected').attr('id'),
                'price': this.modalSeleccion_cuota
            });
            //console.log(datos);

            axios.post('v1/markets/selections', {
                'marketselections': datos
            }).then(function (response) {
                if (parseInt(response.data.http_code) === 200) {
                    me.modalSeleccion_cuota = '';
                    me.alertSuccess = 5;
                    me.listarSeleccionesNoUsadas(cod_market);
                    me.listarSeleccionesSiUsadas(cod_market);
                } else {
                    alert(response);
                }

            }).catch(function (error) {
                console.log(error);
            });

        },
        update_seleccion_x_market(id_seleccion_market) {
            var nueva_cuota=$('#seleccionModal_i_cuota_'+id_seleccion_market).val();
            console.log(id_seleccion_market);
            console.log(cod_market);
            console.log(nueva_cuota);

            let me = this;

            axios.put('v1/markets/selections', {
                'id': id_seleccion_market,
                'price': nueva_cuota
            }).then(function (response) {
                if (parseInt(response.data.http_code) === 200) {
                    me.alertSuccess = 5;
                    //me.listarSeleccionesSiUsadas(cod_market);
                } else {
                    me.alertDanger = 5;
                }

            }).catch(function (error) {
                console.log(error);
            });

        },
        regresar_div_mercado(){
            $("#div_mercado").show();
            $("#div_seleccion_x_mercado").hide();
        }
    },
    mounted(){
      this.listarMercados();
      this.listarEventos();
    }
};


</script>
<style>
.post{
  border: 1px solid #D9D9D9;
  padding: 20px;
  margin-bottom: 10px;

  .btn {
  margin-bottom: 4px;
  margin-right: 6px;
}
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
    margin-right: 8px;
  }
  .c-icon-sm {
    margin-right: 5px;
  }
  .c-icon-lg {
    margin-right: 12px;
  }
}
}
</style>